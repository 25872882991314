/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_mobile_analytics_app_id": "44d57dac6f6c4859966c706a4c2f9ae0",
    "aws_mobile_analytics_app_region": "eu-central-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "44d57dac6f6c4859966c706a4c2f9ae0",
            "region": "eu-central-1"
        }
    },
    "aws_cloud_logic_custom": [
        {
            "name": "PublicLambdaAPI",
            "endpoint": "https://98el982rda.execute-api.eu-central-1.amazonaws.com/prod",
            "region": "eu-central-1"
        },
        {
            "name": "StripeWebhook",
            "endpoint": "https://rak5ypfyle.execute-api.eu-central-1.amazonaws.com/prod",
            "region": "eu-central-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://ykanmb4trrhsfddx3fg7udp4ym.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-vzw7hqcm5fhw5cioulsrrptnuy",
    "aws_cognito_identity_pool_id": "eu-central-1:72104023-87d1-42c0-b69f-ee99f2a5c903",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_2mfs76kYN",
    "aws_user_pools_web_client_id": "4gqruk3lsq64l1v2lvs1gfgbjs",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "amplify-tasty-s3-files121532-prod",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
